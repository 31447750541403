*{
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
.App{
  padding: 5px;
  height: 98vh !important;
  width: 98vw;
  display: flex;
}