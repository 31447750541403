.oicon{
    /* width: 2vw;
    height: 4vh; */
    width: 40px;
    height: 30px;
   
}
.r-icon{
    position: relative;
    margin-left: 3.3vw;
    margin-top: -0.4vh;
    margin-bottom: 2vh;
    display: flex;
}
.f-icon{
    padding: 5px;
    font-size: 1.8rem;
}
.s-icon{
    padding: 5px;
    font-size: 1.8rem;
}
.t-icon{
    padding: 5px;
    font-size: 1.8rem;
}
.hoverfIcon, .hoversIcon, .hovertIcon{
    display: none;
    width: max-content;
    font-size: 0.6rem;
}
.f-icon:hover{
    border-radius: 20px;
   background-color: rgba(146, 146, 146, 0.304);
  

}
.f-icon:hover + .hoverfIcon {
    display: block;
}
.s-icon:hover{
    border-radius: 20px;
    background-color: rgba(146, 146, 146, 0.304);
}
.s-icon:hover + .hoversIcon{
    display: block;
}
.t-icon:hover{
    border-radius: 20px;
    background-color: rgba(146, 146, 146, 0.304);
}
.t-icon:hover + .hovertIcon{
    display: block;
}

.dropdown{
    background-color: rgba(221, 221, 221, 0.933);
    color: rgb(11, 11, 11);
    position: absolute;
    font-size: 0.6rem;
    border-radius: 5px;
    font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
    width: max-content;
    top: -13.5vh;    
}
.doptions{
    padding: 8px;
}
.doptions:hover{
background-color: rgba(85, 85, 85, 0.225);
}


.acc {

	width: 32vw;
  margin-left: 3vw;
}

details {
	margin-bottom: 10px;
}

details:hover {
	filter: drop-shadow(5px 5px 4px #0A0A0A);
}

details > summary {
	color: rgb(60, 60, 60);
  background-color: #bababa;
	padding: 5px 10px;
	font-size: 1.04em;
	cursor: pointer;
  transition: transform 0.05s ease-in-out;
  cursor: pointer;
  animation: slideInRight 0.4s ease-in-out;
}

details > summary:hover{
  background-color: #1363c6;
  color: whitesmoke;
}

details > p {
	padding: 5px 10px 10px 20px;
	color: #515151;
	font-size: 1.02em;
  background: rgb(255, 234, 234);
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
  animation: slideDown 0.8s ease-in-out;
}

.modal-body:focus{
outline: none !important;
}