.mainContainer{
    display: flex;
    justify-content: center;
    align-items: center;
    /* border: 2px solid red; */
    margin-left: 6vw;

    transition: transform 0.05s ease-in-out !important;
    cursor: pointer !important;
    animation: slideInRight 0.5s ease-in-out !important;
}
.innerContainer{
    /* border: 2px solid black; */
    width: 100vh;
    padding: 5px 5px;
    margin: 5rem 0rem;
}
.inputdiv{
    display: flex;
    flex-direction: column;

}
.inputdiv>label{
    margin: 12px 0px;
}
.inputdiv>input{
    height: 2rem;
    font-size: 18px;
    margin-bottom: 15px;
    border: 2px solid black;
    border-radius: 5px;
    padding: 0px 5px;
    /* background-color: #e5f8f9; */
}
.slidervalue{
    height: 25px;
    margin-top: 10px;
}
.slidervalue>span{
   
    transform: translateX(-50%);
}
.thresholdSlider{
    margin-top: 10px;
    margin-bottom: 30px;
   
}

.thresholdSlider>input{
   width: 100%;
   cursor: pointer;
   accent-color: red;
  
}

.min_max{
    display: flex;
    /* border: 2px solid red; */
    justify-content: space-between;
    
}
.answersSlider{
    margin: 15px 0px;
    margin-bottom: 30px;
}
.answerslidervalue{
    height: 25px;
    margin-top: 10px;
}
.answerslidervalue>div{
    transform: translateX(-50%);
}
.answersSlider>input{
    width: 100%;
    cursor: pointer;
    accent-color: red;
}

.q_a_btndiv{
    margin: 15px 0px;
}
.q_a_btndiv>button{
    border-radius: 10px;
    background: none;
    font-size: 16px;
    padding: 8px;
    cursor: pointer;
}
.q_a_btndiv>button:hover{
    background-color: black;
   color: white;
   border: none;
  
   
}
.chatgpt_ans_btndiv{
    margin: 15px 0px;
}
.chatgpt_ans_btndiv>button{
    border-radius: 10px;
    background: none;
    font-size: 16px;
    padding: 8px;
    cursor: pointer;
}
.chatgpt_ans_btndiv>button:hover{
    background-color: black;
    color: white;
    border: none;
 }
 
 @keyframes slideInRight {
    0% {
      transform: translateX(50%); 
      opacity: 0;
    }
    100% {
      transform: translateX(0); 
      opacity: 1;
    }
  }