.MainDiv{
    /* background-color: #000; */
}
.slider{
    position: relative;
     margin: 2rem;
     width: 15rem;
     /* border: 2px solid yellow; */
   /* margin-left: 5rem; */
}
.range-slider{
  -webkit-appearance: none;
  appearance: none;
  width: 100%;
  height: 8px;
  border: 1px solid black;
  /* outline: none; */
  background-color: #ededed;
  cursor: pointer;
}

.range-slider::-webkit-slider-thumb{
    -webkit-appearance: none;
    appearance: none;
    width: 30px;
    height: 30px;
    
    border-radius: 30%;
    border: 1px solid black;

}
#range-slider1::-webkit-slider-thumb{
    background-color: yellow;
}
#range-slider2::-webkit-slider-thumb{
    background-color: cyan;
}
#range-slider3::-webkit-slider-thumb{
    background-color: pink;
}
#range-slider4::-webkit-slider-thumb{
    background-color: green;
}
#range-slider5::-webkit-slider-thumb{
    background-color: purple;
}
#range-slider6::-webkit-slider-thumb{
    background-color: chocolate;
}

.range-slider{
    /* margin: 30px; */
}
.sliderDiv{
    margin: 30px;
    display: flex;
    flex-wrap: wrap;
    /* border: 2px solid red; */
}
.knobsDiv{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    gap: 20px;
    border: 2px solid white;
}

.selectBox{
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 3rem 0rem;
    gap: 10rem;
}
